// react
import { useEffect, useRef, useState } from 'react'
// @mui
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Stack,
  Typography
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { CheckCircleOutline } from '@mui/icons-material'
// hooks
import useAuth from '~/hooks/useAuth'
// services
import { fileService } from '~/services'

// subcomponents
import DropZone from './Dropzone'
import SelectFileModal from './SelectFileModal'
import AccessFileButton from '../AccessFileButton'
import AudioPlayer from '../AudioPlayer'
import VideoPreview from './VideoPreview'

// i18n
// i18n
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'

// ----------------------------------------------------------------------

const ALLOWED_EXTENSIONS = {
  file: ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip'],
  audio: ['mp3', 'wav', 'flac', 'wma', 'ogg', 'm4a'],
  video: ['mp4', 'avi', 'wmv', 'webm', 'mpeg', 'mpg', '3gp']
}

// ----------------------------------------------------------------------

/**
 * Upload file, and get File._id as value
 * @param {Object} props
 * @param {'file'|'audio'|'video'} [props.mode='file'] - 'file' or 'audio'
 * @param {string} props.name
 * @param {(e)=>void} props.onChange
 * @param {string} [props.value=null]
 * @param {string} [props.label='Upload File']
 * @param {React.ReactNode} [props.helperText]
 * @param {string} [props.error]
 * @param {string[]} [props.allowedExtensions]
 * @param {boolean} [props.isAllowExistingFile=true] - will allow user to select existing file instead of uploading new one
 * @returns {JSX.Element}
 */
export default function FileUpload({
  mode = 'file',
  name,
  onChange,
  value,
  label = 'Upload File',
  helperText,
  error,
  isAllowExistingFile = true
}) {
  const { authorizeService } = useAuth()

  const { _ } = useLingui()

  // ------ NEW FILE UPLOAD HANDLING
  // upload state
  const [isUploading, setIsUploading] = useState(false)
  const [errorOverride, setErrorOverride] = useState(false)
  const [isUploadSuccess, setIsUploadSuccess] = useState(false)
  const [existingFileName, setExistingFileName] = useState(null)

  // file state
  const [currentFile, setCurrentFile] = useState(null)
  const [isExistingFileSelected, setIsExistingFileSelected] = useState(false)

  // hidden input field and value push-pull
  const [inputKey] = useState(`${name}-${Date.now()}`)
  const inputRef = useRef(null)

  // upload handler
  useEffect(() => {
    if (!currentFile) return

    // validate file extension
    const fileExt = currentFile.name
      .substring(currentFile.name.lastIndexOf('.') + 1)
      .toLowerCase()
    if (!ALLOWED_EXTENSIONS[mode].includes(fileExt)) {
      const allowedExtText = ALLOWED_EXTENSIONS[mode].join(', ')
      setErrorOverride(
        _(
          msg`Jenis file tidak diperbolehkan, jenis file yang diterima: ${allowedExtText}`
        )
      )
      return
    }

    const uploadFile = async () => {
      setIsUploading(true)
      setIsUploadSuccess(false)
      setErrorOverride(null)

      try {
        const extension = currentFile.name.substring(
          currentFile.name.lastIndexOf('.') + 1
        )

        const newFileUpload = await authorizeService(
          fileService.createUploadURL
        )({
          type: mode,
          extension,
          orginalFileName: currentFile.name
        }).then((res) => res?.data?.results)

        await fileService.uploadFile(newFileUpload.signedURL, {
          mimeType: newFileUpload.mimeType,
          data: currentFile
        })

        onChange(newFileUpload._id)
        setIsUploadSuccess(true)
      } catch (__) {
        setErrorOverride(
          _(
            msg`Terjadi kesalahan saat mengupload file, silahkan coba lagi dalam beberapa saat.`
          )
        )
      } finally {
        setIsUploading(false)
      }
    }

    uploadFile()
  }, [mode, onChange, authorizeService, currentFile])

  const handleOpenFileSelector = () => {
    if (!inputRef.current) return

    inputRef.current.click()
  }

  // ------ NEW FILE FROM SELECTOR HANDLER
  // event handler
  const handleInputFileChange = (e) => {
    if (isUploading) return

    // parse HTML input value
    const file = Array.from(e.target.files)[0]

    if (!file) return

    setCurrentFile(file)
    setExistingFileName(null)
  }

  // ------ DROPZONE HANDLER
  const handleDropzoneDrop = (file) => {
    if (isUploading) return

    setCurrentFile(file)
  }

  const handleDropzoneError = (err) => {
    setErrorOverride(err.message)
  }

  // ------ MODAL HANDLING FOR SELECTING EXISTING FILE
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleModalOpen = () => {
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const handleModalFileSelect = (savedFile) => {
    onChange(savedFile._id)
    setExistingFileName(savedFile.orginalFileName)
    setIsExistingFileSelected(true)
  }

  const [displayFileName, setDisplayFileName] = useState(null)
  useEffect(() => {
    if (existingFileName) {
      setDisplayFileName(existingFileName)
    } else if (currentFile) {
      setDisplayFileName(currentFile.name)
    }
  }, [existingFileName, currentFile])

  return (
    <div>
      {/* CARD ELEMENT */}
      <Box
        sx={{
          padding: 2,
          borderRadius: '1rem',
          border: '1px solid',
          borderColor: 'divider'
        }}
      >
        <Stack spacing={2}>
          <div>
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                xs
              >
                <Typography
                  component='p'
                  sx={{ mb: 0 }}
                >
                  {label}
                </Typography>
              </Grid>

              <Grid
                item
                xs='auto'
              >
                <CheckCircleOutline color={value ? 'primary' : 'disabled'} />
              </Grid>
            </Grid>
          </div>

          <div>
            <DropZone
              isLoading={isUploading}
              isCurrentFileExist={Boolean(value)}
              onClick={handleOpenFileSelector}
              onDrop={handleDropzoneDrop}
              onError={handleDropzoneError}
            />

            <FormHelperText error={error || errorOverride}>
              {errorOverride ||
                error ||
                (isUploadSuccess || isExistingFileSelected ? (
                  <Stack
                    direction='row'
                    marginTop={1}
                    spacing={0.5}
                  >
                    <Typography
                      sx={{
                        fontSize: 'inherit',
                        fontWeight: 'bold',
                        color: 'primary.main'
                      }}
                    >
                      {_(msg`File berhasil diupload`)}:
                    </Typography>
                    <Typography
                      color='black'
                      fontSize='inherit'
                    >
                      {displayFileName}
                    </Typography>
                  </Stack>
                ) : (
                  helperText || (
                    <>
                      {_(
                        msg`Format file: ${ALLOWED_EXTENSIONS[mode].join(', ')}`
                      )}
                      <br />
                      {_(
                        msg`Ukuran maksimal file ${
                          mode === 'video' ? '100MB' : '10MB'
                        }`
                      )}
                    </>
                  )
                ))}
            </FormHelperText>
          </div>

          <div>
            <Grid
              container
              spacing={1}
            >
              {isAllowExistingFile && (
                <Grid
                  item
                  xs='auto'
                >
                  <Button
                    variant='outlined'
                    size='small'
                    onClick={handleModalOpen}
                    disabled={isUploading}
                  >
                    {_(msg`Pilih file sebelumnya`)}
                  </Button>
                </Grid>
              )}

              {/* PREVIEW FOR FILE UPLOAD */}
              {mode === 'file' && (
                <Grid
                  item
                  xs='auto'
                >
                  <AccessFileButton
                    size='small'
                    variant='outlined'
                    fileId={value}
                    disabled={isUploading || !value}
                  >
                    {_(msg`Cek file`)}
                  </AccessFileButton>
                </Grid>
              )}

              {mode === 'video' && (
                <Grid
                  item
                  xs='auto'
                >
                  <VideoPreview
                    fileId={value}
                    buttonProps={{
                      size: 'small',
                      variant: 'outlined'
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </div>

          {/* PREVIEW FOR AUDIO FILE */}
          {mode === 'audio' && (
            <div>
              <AudioPlayer
                fileId={value}
                accessContext={{
                  contextType: 'Author',
                  contextId: '$user._id'
                }}
              />
            </div>
          )}
        </Stack>
      </Box>

      {/* MODAL ELEMENT */}
      {isAllowExistingFile && (
        <SelectFileModal
          mode={mode}
          isOpen={isModalOpen}
          onClose={handleModalClose}
          currentFileId={value}
          onFileSelect={handleModalFileSelect}
        />
      )}

      {/* INPUT ELEMENT */}
      <Box sx={visuallyHidden}>
        <label htmlFor={inputKey}>{label}</label>
        <input
          id={inputKey}
          key={inputKey}
          ref={inputRef}
          type='file'
          accept={ALLOWED_EXTENSIONS[mode].map((ext) => `.${ext}`).join(',')}
          onChange={handleInputFileChange}
        />
      </Box>
    </div>
  )
}
